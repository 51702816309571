const styles = {
    background: {
        px: '5vw',
        mt: { 
            md: 5,
            xs: 2
        },
        mb: {
            md: 8,
            xs: 3
        },
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    warning: {
        marginTop: 4,
        opacity: .9,
        mx: { 
            md: 6,
            xs: .8
        }
    },
    awardArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        px: 2,
        mt: 2
    },
    awardImg: {
        width: { 
            md: '12vw',
            sm: '25vw',
            xs: '40vw'
        }
    },
    position: {
        fontWeight: 'bold',
        my: 1.5,
    }
}

export default styles