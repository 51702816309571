import React from 'react'
import Award from './Award'
import styles from './styles'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import AWARDS from '@constants/awards'
import { useFolderImages } from '@hooks'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { Feedback } from '@components/molecules'

const ListAwards = props => {
    const { edition, warning } = props
    const data = AWARDS.filter(award => award.edition === Number(edition))
    const imagesData = useFolderImages('awards')

    if (data.length === 0) return <Feedback description='Ainda não definimos os prêmios desta edição, mas em breve eles serão escolhidos e colocados aqui'/>
    return (
        <Box sx={ styles.background }>
            <Box sx={ styles.list }>
                <Grid container alignItems='center' justify='space-between' spacing={ 3 }>
                    { data.map((award, i) => {
                        const image = imagesData.find(({ name }) => name === award.image)

                        return <Award key={ i } { ...award } image={ image }/>
                    })}
                </Grid>
            </Box>
            { warning &&
                <Alert severity="warning" sx={ styles.warning }>
                    <AlertTitle>Atenção</AlertTitle>
                    { warning }
                </Alert>
            }
        </Box>
    )
}

export default ListAwards