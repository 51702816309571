import React from 'react'
import styles from './styles'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Box from '@mui/material/Box'

const Award = props => {
    const { title, description, position, image } = props

    return (
        <Grid item xs={ 12 } md={ 4 }>
            <Box sx={ styles.awardArea }>
                <Box sx={ styles.awardImg }>
                    <GatsbyImage image={ getImage(image) } alt='Imagem do prêmio' style={{ width: '100%' }}/>
                </Box>
                <Typography color='primary' variant='h5' sx={ styles.position }>
                    { position }º Lugar
                </Typography>
                <Typography color='secondary' align='center' variant='h5' gutterBottom style={{ whiteSpace: 'pre-wrap' }}>
                    { title }
                </Typography>
                <Typography color='secondary' align='center'>
                    { description }
                </Typography>
            </Box>
        </Grid>
    )
}

export default Award