const AWARDS = [
    {
        title: `R$ 1.200,00*`,
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'gold_ingots',
        position: 1,
        edition: 2021
    },
    {
        title: `R$ 600,00*`,
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'money_bag',
        position: 2,
        edition: 2021
    },
    {
        title: 'Amazon Alexa',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'alexa',
        position: 3,
        edition: 2021
    },
    {
        title: 'R$ 1.200,00',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'gold_ingots',
        position: 1,
        edition: 2020
    },
    {
        title: 'R$ 600,00',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'money_bag',
        position: 2,
        edition: 2020
    },
    {
        title: 'R$ 300,00',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'gold_coins',
        position: 3,
        edition: 2020
    },
    {
        title: 'R$ 1.000,00',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'gold_ingots',
        position: 1,
        edition: 2019
    },
    {
        title: 'Câmera Polaroid',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'polaroid',
        position: 2,
        edition: 2019
    },
    {
        title: 'Livro de fotografia',
        description: 'Inclui Certificado + Foto premiada com moldura e vidro-antirreflexo no tamanho 30X40',
        image: 'photo_book',
        position: 3,
        edition: 2019
    }
]

export default AWARDS