import React, { useState } from "react"
import { Layout } from "@components/organisms"
import { PageHeader } from "@components/molecules"
import EDITIONS from "@constants/editions"
import { AwardsList } from '@pagesComponents/awards'
import { Link } from 'gatsby'
import { Tabs } from "@components/atoms"

const editionsArr = Object.values(EDITIONS).filter(el => el.id !== 2018).reverse()

const WARNING = {
    2021: <>
        *Estes são valores referentes a categoria "Cooperados". Aos participantes vencedores das outras categorias que não sejam cooperados, nas categorias
        “Não Cooperados” e “Profissionais”, o valor do prêmio poderá ser diferente. Saiba mais acessando a aba <Link to='/edital#artigo10'>Edital</Link>
    </>
}

const Awards = () => {
    const [edition, setEdition] = useState(0)
    const selected = editionsArr[edition]

    return (
        <Layout seo={{ title: 'Prêmios', description: 'Prêmios do Concurso de Fotografia - Foco no Cooperativismo' }}> 
            <PageHeader icon={{ name: 'gift', alt: 'Icone presente' }} description='Saiba mais sobre os prêmios que serão entregues para as melhores
                fotos de cada categoria do concurso'
            >
                Prêmios
            </PageHeader>
            <Tabs selected={ edition } onChange={ key => setEdition(key) } tabs={ editionsArr.map(el => ({ caption: el.id, label: `${ el.index }ª edição` })) }/>
            <AwardsList edition={ selected.id } warning={ WARNING[selected.id] }/>
        </Layout>
    )
}

export default Awards